<template>
  <div class="lookPayType">
    <!-- 头部 -->
    <van-nav-bar fixed placeholder z-index="100" title="查看"   left-arrow @click-left="onClickLeft" />

    <!-- 内容 -->
    <div class="content">
        <div class="cbox">
             <van-loading />
        </div>
    </div>
  </div>
</template>

<script>
import { checkOrderPay } from "../api/user";
  export default {
    data(){
      return{
         paydata:''
      }
    },
    methods:{
        onClickLeft(){
           this.$router.go(-1)
        },
        onClick(){
           this.$router.push('/home/InformationBase')
        },
        async lookOutType(){
             const info = {
                 oid:this.paydata.oid,
                 orderType:this.paydata.orderType
             }
             const result = await checkOrderPay(JSON.stringify(info))
             if(result.code === 0 ){
                 if(result.data.isPay == 1){
                   this.$router.push({
                      name:"payError",
                      query:{
                        oid:this.paydata.oid,
                        userId:this.paydata.userId,
                        orderType:this.paydata.orderType
                      }
                   })
                 }else{
                    this.$router.push({
                      name:"paySuccess",
                      query:{
                        paydata:{
                           oid:result.data.oid,
                           price:result.data.price,
                           payType:result.data.payType
                        }
                      }
                    })
                 }
             }else{
               this.$toast("系统错误，请联系管理员！")
             }
        },
    },
    created() {
       this.paydata = eval('(' + this.$route.query.payData + ')')
    },
    mounted() {
       setTimeout(this.lookOutType(),3000)
    },
  }
</script>

<style lang="scss" scoped>
.lookPayType{
   font-family: PingFang SC;
    ::v-deep .van-nav-bar__text{
      color: #333333;
      font-size: 15px;
      font-weight: 500;
    }
    ::v-deep .van-nav-bar .van-icon{
      color: #333333;
    }
    ::v-deep .van-nav-bar__title{
      font-size: 18px;
      font-weight: bold;
      color: #333333;
    }
    ::v-deep .van-nav-bar__content{
      height: 57px;
    }
  .content{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80vh;
    .cbox{
       display: flex;
       justify-content: center;
       flex-direction: column;
       align-items: center;
         ::v-deep .van-loading{
            width: 120px;
            height: 120px;
            .van-loading__spinner{
              width: 120px;
              height: 120px;
            }
          }
    }
  }  
}
</style>